<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp">
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t('table.headers.factoryName') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-autocomplete
              v-model="customer"
              :items="customers"
              :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
              item-value="id"
              :no-data-text="$t('table.messages.no_data')"
              clearable
              single-line
              outlined
            />
          </div>
        </v-col>
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t('table.headers.dateRange') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-dialog
              ref="dialog"
              v-model="modalCalendar"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  outlined
                  clearable
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  @click:clear="clear"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                locale="ja"
                scrollable
              >
                <v-spacer />

                <v-btn
                  text
                  color="primary"
                  @click="choosenDate"
                >
                  {{ $t('messages.ok') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="modalCalendar = false"
                >
                  {{ $t('messages.cancel') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="d-flex"
        >
          <div class="align-self-center mr-9">
            <v-btn
              large
              color="forth"
              class="pt-0 pl-10 pr-10 white--text d-flex justify-star"
              @click="onSearch()"
            >
              <span class="font-weight-bold text-h5">
                {{ $t('messages.display') }}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="2"
        >
          <v-btn
            large
            color="forth"
            class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-star"
            @click="openDialog"
          >
            <span class="font-weight-bold text-h5">
              {{ $t('messages.addShift') }}</span>
          </v-btn>
        </v-col>
        <v-col>
          <v-row class="d-flex justify-end">
            <v-col cols="5">
              <div class="align-self-center">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0"
                  :placeholder="$t('placeholder.shiftList')"
                  outlined
                  clearable
                  @keydown.enter="initialize"
                />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="onSearch()"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.search') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            class="elevation-1 "
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :items-per-page="10"
            :server-items-length="this.total"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
          >
            <template
              v-slot:[`footer.page-text`]
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>

            <template v-slot:[`item.index`]="props">
              {{ getIndex(props.index) }}
            </template>

            <template v-slot:[`item.name`]="props">
              <span
                class="font-weight-bold textName"
                @click="getDetailShift(props.item)"
              >
                {{ getValueObject(props.item, 'name') }}
              </span>
            </template>

            <template v-slot:[`item.factoryName`]="props">
              {{ getValueObject(props.item,'customer.user.name') }}
            </template>
            <template v-slot:[`item.month`]="props">
              {{ getDataFormatDate(getValueObject(props.item, 'timestamps')) }}
            </template>
            <template v-slot:[`item.updatedAt`]="props">
              {{ convertTime(getValueObject(props.item, 'updatedAt'), 'YYYY/MM/DD HH:mm') }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon
                dark
                color="#ef3e56"
                @click="deleteItem(item)"
              >
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogCreateTable"
      width="687"
    >
      <v-card>
        <validation-observer
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(submitCreatTable)"
          >
            <v-card-title
              class="font-weight-bold text-h4 color-current-date mb-5"
            >
              <v-row>
                <v-col>
                  <div class="text-center">
                    固定シフトを作成ために以下の情報をご指定ください。
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text style="padding: 10px 50px">
              <v-row>
                <v-col>
                  <h3 style="line-height: 60px">
                    {{ $t('messages.factoryName') }}
                  </h3>
                </v-col>
                <v-col cols="8">
                  <validation-provider
                    v-slot="{ errors }"
                    name="customerSelected"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="customerSelected"
                      :items="customerList"
                      :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                      item-value="id"
                      name="customerSelected"
                      :error-messages="errors[0]"
                      :no-data-text="$t('table.messages.no_data')"
                      clearable
                      single-line
                      outlined
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3 style="line-height: 60px">
                    {{ $t('table.headers.dateRange') }}
                  </h3>
                </v-col>
                <v-col cols="8">
                  <v-dialog
                    ref="dialogCalenda"
                    v-model="modalCalendarPopup"
                    :return-value.sync="dateSelected"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="dateSelected"
                        rules="required"
                      >
                        <v-text-field
                          v-model="dateSelected"
                          outlined
                          clearable
                          append-icon="mdi-calendar"
                          name="dateSelected"
                          :error-messages="errors[0]"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="dateSelected"
                      type="month"
                      locale="ja"
                      scrollable
                    >
                      <v-spacer />

                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogCalenda.save(dateSelected)"
                      >
                        {{ $t('messages.ok') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="modalCalendarPopup = false"
                      >
                        {{ $t('messages.cancel') }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn
                large
                color="accent"
                type="submit"
              >
                {{ $t('messages.save') }}
              </v-btn>
              <v-btn
                large
                @click="reset"
              >
                {{ $t('messages.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。
              本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="deleteItemConfirm"
          >
            {{ $t('messages.delete') }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="closeDelete"
          >
            {{ $t('messages.cancel') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  import { convertTime } from '../../util/covertTime'

  export default {
    name: 'ShiftList',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        items: [],
        search: '',
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '5%',
          },
          {
            text: this.$t('table.headers.shiftName'),
            align: 'center',
            value: 'name',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '30%',
          },
          {
            text: this.$t('table.headers.factoryName'),
            align: 'center',
            value: 'factoryName',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '20%',
          },
          {
            text: this.$t('table.headers.dateRange'),
            align: 'center',
            value: 'month',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '20%',
          },
          {
            text: this.$t('table.headers.updatedTime'),
            align: 'center',
            value: 'updatedAt',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '15%',
          },
          {
            text: '',
            align: 'center',
            value: 'action',
            class: 'font-weight-bold',
            sortable: false,
            width: '10%',
          },
        ],
        options: {},
        loading: false,
        snackbarMessage: '',
        snackbar: false,
        customer: null,
        date: null,
        customers: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        dialogCreateTable: false,
        customerId: '',
        modalCalendar: false,
        modalCalendarPopup: false,
        customerSelected: null,
        dateSelected: '',
        dialogDelete: false,
        editedItem: {},
      }
    },
    watch: {
      dialogCreateTable () {
        this.$refs.form && this.$refs.form.reset()
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      search () {
        this.onSearch()
      },
      shiftPeriodList (value) {
        this.$set(this, 'items', value)
      },
      customerList (value) {
        value.forEach(item => {
          this.customers.push(item)
        })
      },
      customer () {
        this.options.page = 1
        this.initialize()
      },
      message (value) {
        if (value === 'success') {
          this.customerSelected = null
          this.dateSelected = ''
          this.snackbarMessage = this.$t('messages.success')
          this.dialogCreateTable = false
          this.options.page = 1
          this.initialize()
        }
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
    created () {
      this.getCustomers()
    },
    mounted () {
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
    },

    methods: {
      convertTime,
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      getNameParttime (parttime) {
        return _.get(parttime, 'manageId') + ' - ' + _.get(parttime, 'name', '')
      },
      getDetailShift (item) {
        localStorage.itemShift = JSON.stringify(item)
        this.$router.push('/fix-shift/' + item.id)
      },
      getCustomers () {
        const params = {
          isFixed: true,
        }
        this.$store.dispatch('customer/getCustomers', params)
      },
      initialize () {
        const tmpOptions = {
          isFixed: true,
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage || 0,
        }
        if (this.date) {
          tmpOptions.month = JSON.stringify(this.date)
        }
        if (this.customer) {
          tmpOptions.customerId = this.customer
        }
        if (this.search.trim()) {
          tmpOptions.keyword = this.search.trim()
        }
        this.$store.dispatch('shift/getPeriodShift', tmpOptions)
      },
      onSearch () {
        if (this.date || this.search) {
          this.options.page = 1
        }
        this.initialize()
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      getDate (value) {
        return moment(value).format('YYYY-MM-DD')
      },
      async submitCreatTable () {
        const payload = {
          customerId: this.customerSelected,
          month: this.dateSelected,
        }
        try {
          await this.$store.dispatch('fixedShift/createListFixed', payload)
        } catch {}
        // this.$router.push({ path: '/detail-time-sheet', query: payload })
      },
      reset () {
        this.dialogCreateTable = false
      },
      openDialog () {
        this.dialogCreateTable = true
        this.$refs.observer && this.$refs.observer.reset()
        this.customerSelected = null
        this.dateSelected = null
      },
      choosenDate () {
        this.$refs.dialog.save(this.date)
        this.initialize()
      },
      clear () {
        this.date = ''
        this.initialize()
      },
      getDataFormatDate (value) {
        const datesSelect = JSON.parse(value)
        return datesSelect[0] ? moment(datesSelect[0]).format('YYYY-MM') : ''
      },
      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      async deleteItemConfirm () {
        await this.$store.dispatch('shift/deletePeriod', this.editedItem.id)
        this.dialogDelete = false
        this.initialize()
      },
      closeDelete () {
        this.editedItem = {}
        this.dialogDelete = false
      },
    },
    computed: {
      ...get('shift', ['message', 'status', 'error', 'shiftPeriodList', 'total']),
      ...get('fixedShift', ['message', 'status', 'error', 'fixedInfor']),
      customerList: get('customer@list.data.customers'),
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12) !important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblText
    margin-top: 10px

  .lblTitle
    color: #5CA6D2

.dateWarp
  display: flex
  justify-content: space-between

  .icon-fall
    font-size: 20px
    font-weight: bold
    margin-top: 10px

.titleFlex
  display: flex
  align-items: center

.btnFlex
  display: flex
  align-items: center

  button
    margin: 0px !important

.shiftAt
  margin-left: 0px !important
.v-dialog
  overflow-y: hidden!important
.lblText
  color: #25ACD8
.borderDialog
  margin-top: 24px
  border: 1px solid #25ACD8
  border-radius: 5px
  padding: 10px
.iconSuccess__lange
  font-size: 70px
.textName
  cursor: pointer
  color: #37CCC5
::v-deep .color-current-date
  background-color:#B6EDFF
  opacity : 80%

</style>
